import _axios from 'axios';
import { PatientDemographics } from './HapiFhirClient';

const axios = _axios.create({
  baseURL: 'https://api.diseasevision.com'
});

export type PatientSummaries = Array<{
  diseaseName: string;
  rank: number;
  summary: string;
}>;

export const getPatientSummaries = async (patientDemographics: PatientDemographics): Promise<PatientSummaries> => {
  let raceCode: string;
  switch (patientDemographics.race) {
    case 'Asian':
      raceCode = 'A-PI';
      break;
    case 'Black or African American':
      raceCode = '2054-5';
      break;
    case 'White':
      raceCode = '2106-3';
      break;
    default:
      throw new Error('No race code for you!');
  }

  let gender: string;
  switch (patientDemographics.gender) {
    case 'Male':
      gender = 'M';
      break;
    case 'Female':
      gender = 'F';
      break;
    default:
      throw new Error('No gender for you!');
  }

  const headers = {
    'Content-Type': 'application/json'
  };

  const data = await axios.post(
    `/disease-risk`,
    {
      age: patientDemographics.age,
      gender,
      race: raceCode,
      state: patientDemographics.state
    },
    {
      headers
    }
  );

  return data.data.summaries;
};
