import React from 'react';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom'
import { HomeScreen } from './screens/HomeScreen';
import './App.css';
import { Login } from './screens/Login';
import { Registration } from './screens/Registration';
import { UserSelection } from './screens/UserSelection';

const App = () => {
  return (
    <BrowserRouter basename='/'>
        <Routes>
          <Route path='' element={
            <Navigate to="/login" />
          }/>
          <Route path='home' element={
            <HomeScreen />
          }/>
          <Route path='login' element={
            <Login />
          }/>
          <Route path='user-selection' element={
            <UserSelection />
          }/>
          <Route path='registration' element={
            <Registration />
          }/>
        </Routes>
    </BrowserRouter>
  );
};

export default App;
