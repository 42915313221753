import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { LoginButton } from './LogInButton';
import { RegisterButton } from './RegisterButton';
import { TextField } from '@mui/material';
import { IncorrectLoginButton } from './IncorrectLoginButton';

const PASSWORD = 'CS6440';

export const LoginHeader = () => {
  const [password, setPassword] = useState<string | undefined>(undefined);

  return (
    <>
      <Navbar bg="primary" variant="dark">
      <Container id='navBarSelections'>
        <Navbar.Brand id='navBarCompanyName'>Disease Vision</Navbar.Brand>
      </Container>
      <Container id='navBarButtonSection'>
        <RegisterButton />
        <div id='navBarPassword'>
          <TextField id="filled-basic" label="Password" variant="filled" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(event.target.value);
          }} />
        </div>
        {password === PASSWORD &&
          <LoginButton />
        }
        {password !== PASSWORD &&
          <IncorrectLoginButton />
        }
      </Container>
      </Navbar>
    </>
  )
};
