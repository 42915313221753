import React from 'react';
import { Footer } from "../components/Footer";
import { RegistrationHeader } from '../components/RegistrationHeader';
import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography, styled } from '@mui/material';
import { AccountCircle, DoubleArrow } from '@mui/icons-material';

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  marginLeft: '35%',
  marginRight: '35%'
}));

export const UserSelection = () => {
  return (
    <>
      <RegistrationHeader />

      <div id="loginScreenBackground" style={{paddingBottom: '3vh', paddingTop: '0.1vh'}}>
        <Demo>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Select user
          </Typography>

          <List dense={false}>
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="delete" href='/home?name=brenda-xi&patientId=93fa5542-fd80-4575-805f-7a0ee7e53ddf'>
                  <DoubleArrow />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <AccountCircle />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Brenda Xi"
                secondary={'SSN: 999-47-3907'}
              />
            </ListItem>

            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="delete" href='/home?name=vartha-bratton&patientId=1092110'>
                  <DoubleArrow />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <AccountCircle />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Vartha Bratton"
                secondary={'SSN: 999-47-9810'}
              />
            </ListItem>

            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="delete" href='/home?name=toby-kiddle&patientId=1093773'>
                  <DoubleArrow />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <AccountCircle />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Toby Kiddle"
                secondary={'SSN: 999-43-1856'}
              />
            </ListItem>

            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="delete" href='/home?name=alejandra-jeron&patientId=46edbd1d-01ba-4221-aa51-39efb24b37a6'>
                  <DoubleArrow />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <AccountCircle />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Alejandra Jeron"
                secondary={'SSN: 999-22-9009'}
              />
            </ListItem>

            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="delete" href='/home?name=clifton-mills&patientId=76a48d29-2933-4254-9c26-a2a3e85fc8ec'>
                  <DoubleArrow />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <AccountCircle />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Clifton Mills"
                secondary={'SSN: 999-88-9503'}
              />
            </ListItem>

            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="delete" href='/home?name=erica-leannon&patientId=34088dbd-4fb5-4b13-a037-a0b538d04843'>
                  <DoubleArrow />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <AccountCircle />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Erica Leannon"
                secondary={'SSN: 999-78-6989'}
              />
            </ListItem>
          </List>
        </Demo>
      </div>

      <Footer />
    </>
  )
};
