import _axios from 'axios';
import { DateUtility } from '../utility/DateUtility';

const axios = _axios.create({
  baseURL: 'https://hapi.fhir.org/baseR4'
});

// Races:
// American Indian or Alaska Native
// Asian
// Black or African American
// Native Hawaiian or Other Pacific Islander
// White

type PatientsFound = {
  americanPatients: Array<string>;
  asianPatients: Array<string>;
  blackPatients: Array<string>;
  islanderPatients: Array<string>;
  whitePatients: Array<string>;
};

export type PatientDemographics = {
  race: string;
  age: number;
  gender: string;
  state: string;
};

export const getPatientDemographics = async (patientId: string): Promise<PatientDemographics> => {
  const data = await axios.get(`/Patient/${patientId}`);

  const age = DateUtility.getAgeFromBirthDate(data.data.birthDate);
  const gender = data.data.gender.charAt(0).toUpperCase() + data.data.gender.slice(1);
  const state = data.data.address[0].state;

  let race: string;
  const usRaceExtension = data.data.extension.find((extension: any) => {
    return extension.url === 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-race';
  });

  if (usRaceExtension) {
    if (usRaceExtension?.extension) {
      const raceValueCodingExtension = usRaceExtension.extension.find((extensionCoding: any) => {
        return Object.keys(extensionCoding).includes('valueCoding');
      });
      race = raceValueCodingExtension.valueCoding.display;
    } else {
      race = usRaceExtension.valueCodeableConcept.coding[0].display;
    }
  } else {
    const usRaceExtension = data.data.extension.find((extension: any) => {
      return extension.url === 'http://hl7.org/fhir/StructureDefinition/us-core-race';
    });
    race = usRaceExtension.valueCodeableConcept.coding[0].display;
  }

  return {
    race,
    age,
    gender,
    state
  } as PatientDemographics;
};

export const findPatients = async () => {
  const patientsFound: PatientsFound = await getPatients(2000);

  console.log(`AMERICAN PATIENTS = ${patientsFound.americanPatients}`);
  console.log(`ASIAN PATIENTS = ${patientsFound.asianPatients}`);
  console.log(`BLACK PATIENTS = ${patientsFound.blackPatients}`);
  console.log(`ISLANDER PATIENTS = ${patientsFound.islanderPatients}`);
  console.log(`WHITE PATIENTS = ${patientsFound.whitePatients}`);
};

const getPatients = async (patientSearchPages: number): Promise<PatientsFound> => {
  let data = await axios.get('/Patient');

  let allPatientsIterated = false;
  let patientsSearched = 0;

  const patientsFound: PatientsFound = {
    americanPatients: [],
    asianPatients: [],
    blackPatients: [],
    islanderPatients: [],
    whitePatients: []
  };

  while (!allPatientsIterated && patientsSearched < patientSearchPages) {
    console.log(`searching patient iteration #${patientsSearched}`);

    data.data.entry.forEach((patient: any) => {
      const stringified = JSON.stringify(patient).toLowerCase();
      if (
        patient.resource.address &&
        patient.resource.birthDate &&
        patient.resource.gender &&
        stringified.includes('us-core-race') &&
        patient.resource.address[0].state !== 'Massachusetts'
      ) {
        if (stringified.includes('american indian')) {
          patientsFound.americanPatients.push(patient.resource.id);
        }

        if (stringified.includes('asian')) {
          patientsFound.asianPatients.push(patient.resource.id);
        }

        if (stringified.includes('black or african american')) {
          patientsFound.blackPatients.push(patient.resource.id);
        }

        if (stringified.includes('pacific')) {
          patientsFound.islanderPatients.push(patient.resource.id);
        }

        if (stringified.includes('white')) {
          patientsFound.whitePatients.push(patient.resource.id);
        }
      }
    });

    if (data.data.link[1]) {
      data = await axios.get(data.data.link[1].url);
    } else {
      allPatientsIterated = true;
    }

    patientsSearched += 1;
  }

  return patientsFound;
};

export const findSpecificPatient = async (
  patientSearchPages: number,
  race: string,
  latestBirthDate: string,
  gender: string,
  excludeStates: Array<string>
): Promise<Array<string>> => {
  let data = await axios.get('/Patient');

  let allPatientsIterated = false;
  let patientsSearched = 0;
  let patientsFound: Array<string> = [];

  while (!allPatientsIterated && patientsSearched < patientSearchPages) {
    console.log(`searching patient iteration #${patientsSearched}`);

    data.data.entry.forEach((patient: any) => {
      const stringified = JSON.stringify(patient).toLowerCase();
      if (
        patient.resource.birthDate &&
        patient.resource.birthDate < latestBirthDate &&
        patient.resource.gender &&
        patient.resource.gender === gender &&
        stringified.includes('http://hl7.org/fhir/us/core/structuredefinition/us-core-race') &&
        stringified.includes(race) &&
        patient.resource.address &&
        patient.resource.address[0] &&
        patient.resource.address[0].state &&
        patient.resource.address[0].state.length === 2 &&
        !excludeStates.includes(patient.resource.address[0].state)
      ) {
        patientsFound.push(patient.resource.id);
      }
    });

    if (data.data.link[1]) {
      data = await axios.get(data.data.link[1].url);
    } else {
      allPatientsIterated = true;
    }

    patientsSearched += 1;
  }

  console.log(`PATIENTS FOUND: ${patientsFound}`);
  return patientsFound;
};
