import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header"
import { Alert, CircularProgress } from '@mui/material';
import { PatientDemographics, getPatientDemographics } from '../services/HapiFhirClient';
import { PatientSummaries, getPatientSummaries } from '../services/DiseaseVisionClient';


export const HomeScreen = () => {
  jQuery(function(){
    $(".navbar-active").removeClass("navbar-active");
    $("#navbar-home").addClass("navbar-active");
  });

  const [patientDemographics, setPatientDemographics] = useState<PatientDemographics | undefined>(undefined);
  const [patientSummaries, setPatientSummaries] = useState<PatientSummaries | undefined>(undefined);

  const search = useLocation().search;
  const urlName = new URLSearchParams(search).get("name");

  const urlfirstName = urlName?.split('-')[0] as string;
  const firstName = urlfirstName.charAt(0).toUpperCase() + urlfirstName.slice(1);
  const urlLastName = urlName?.split('-')[1] as string;
  const lastName = urlLastName.charAt(0).toUpperCase() + urlLastName.slice(1);
  const name = `${firstName} ${lastName}`;

  useEffect(() => {
    const search = window.location.search;
    const patientId = new URLSearchParams(search).get("patientId");

    const getHapiFhirData = async () => {
      const patientData = await getPatientDemographics(patientId!);
      setPatientDemographics(patientData);

      const patientSummariesRetrieved = await getPatientSummaries(patientData);
      setPatientSummaries(patientSummariesRetrieved);
    }

    getHapiFhirData()
 }, [])

  return (
    <>
      <Header />

      {!patientDemographics &&
        <CircularProgress style={{
          marginTop: '15vh',
          marginBottom: '15vh',
          marginLeft: '50%',
          marginRight: '50%'}}
        />
      }

      {patientDemographics &&
        <div id="body" style={{paddingTop: '3vh', paddingBottom: '3vh', display: 'block'}}>
          <p id="homeScreenMetadata"><strong>Name:</strong> {name}</p>
          <p id="homeScreenMetadata"><strong>Age:</strong> {patientDemographics.age}</p>
          <p id="homeScreenMetadata"><strong>Gender:</strong> {patientDemographics.gender}</p>
          <p id="homeScreenMetadata"><strong>Race:</strong> {patientDemographics.race}</p>
          <p id="homeScreenMetadata"><strong>State:</strong> {patientDemographics.state}</p>

          {!patientSummaries &&
            <div>
              <p id="homeScreenSummaryHeader">Gathering patient's disease risks...</p>

              <CircularProgress style={{
                marginTop: '15vh',
                marginBottom: '15vh',
                marginLeft: '50%',
                marginRight: '50%'}}
              />
            </div>
          }

          {patientSummaries &&
            <div>
              <p id="homeScreenSummaryHeader">Summary of patient's top {patientSummaries.length} disease risks</p>

              <Alert id="homeScreenSummaryBody" variant="outlined" icon={false} severity="warning">
                {patientSummaries.map((data, index) => {
                  return [<p>{index+1}. <strong>{data.diseaseName}:</strong></p>, <p>{data.summary}</p>]
                })}
              </Alert>
            </div>
          }
        </div>
      }

      <Footer />
    </>
  )
};
