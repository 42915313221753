import React from 'react';
import { Footer } from "../components/Footer";
import { RegistrationHeader } from "../components/RegistrationHeader";

export const Registration = () => {
  return (
    <>
      <RegistrationHeader />
      <div id="body">
        <p id="screenTopBodyText">Coming soon!</p>
        <p id="screenTopBodyText">Note: we will not be implementing this screen for the scope of this project since our login information is stored locally</p>
      </div>
      <Footer />
    </>
  )
};