import React from 'react';
import { Footer } from "../components/Footer";
import { LoginHeader } from "../components/LoginHeader";
import loginImage7 from "../screen-images/image7.jpg";
import loginImage2 from "../screen-images/image2.jpg";
import loginImage14 from "../screen-images/image14.jpg";
import loginImage13 from "../screen-images/image13.jpg"

export const Login = () => {
  return (
    <>
      <LoginHeader />

      <div id="loginScreenBackground">
        <img id='loginScreenImage' src={loginImage7} alt=""/>
        <img id='loginScreenImage' src={loginImage2} alt=""/>
        <img id='loginScreenImage' src={loginImage13} alt=""/>
        <img id='loginScreenImage' src={loginImage14} alt=""/>
      </div>

      <Footer />
    </>
  )
};
